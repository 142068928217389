.furniture-types {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            width: 48%;
        }
    }

    &__image {
        margin: 0 auto;
    }

    &__name {
        width: 100%;
        font-size: 24px;
        text-align: center;

        @include media-breakpoint-up(md) {
            margin-bottom: 20px;
            text-align: left;
        }
    }

    &__list {
        width: 100%;
        padding-left: 0;
        list-style: none;

        li {
            list-style: none;
            line-height: 1.4em;
            font-size: 16px;
            text-transform: lowercase;

            @include media-breakpoint-up(md) {
                font-size: 14px;
            }

            &::before {
                content: '-';
                margin-right: 7px;
                margin-top: 50px;
            }
        }
    }
}

@import './certificates/styles';
