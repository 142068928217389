.ordering-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 30px;

    &__item {
        width: 100%;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: 48%;
        }
    }

    &__image {
        position: relative;
        left: -30px;
    }

    &__text {
        font-size: 15px;
    }
}
