.page-service {

    &__important {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    &__button {
        @include button;

        flex-grow: 1;
        background: $base-orange;

        &:hover {
            background: darken($base-orange, 2);
        }
    }

}
