.footer {
    padding: 30px 29px 26px;
    background: $color-purple;

    &__container {

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @include media-breakpoint-up(lg) {
            @include wrapper;
        }
    }

    &__left {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            flex-direction: column;
            width: 20%;
        }
    }

    &__copy {
        margin-top: 14px;
        margin-left: 7px;
        font-size: 10px;

        @include media-breakpoint-up(lg) {
            font-size: 12px;
        }
    }

    &__menu {
        list-style: none;
        padding-left: 0;

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
        }

        @include media-breakpoint-up(lg) {
            width: 100%;
        }

        li {

            @include media-breakpoint-up(lg) {
                margin: 0 1%;
            }
        }

        a {
            font-size: 14px;
            color: $black;
            text-decoration: underline;

            &:hover {
                color: $black;
                text-decoration: none;
            }

            @include media-breakpoint-up(md) {
                padding: 0 3px;
                font-size: 13px;
            }

            @include media-breakpoint-up(lg) {
                padding: 0;
                font-size: 14px;
            }
        }
    }

    &__right {

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 80%;
        }

        @include media-breakpoint-up(lg) {
            width: 75%;
        }
    }

    &__contacts {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }

    &__address {
        font-size: 13px;

        @include media-breakpoint-up(md) {
            text-align: center;
        }

        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }
    }

    &__phone {
        font-size: 27px;
        font-weight: 700;
        color: $black;

        @include media-breakpoint-up(md) {
            font-size: 21px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 24px;
        }

        span {
            font-size: 17px;
            font-weight: 400;
            color: $note-color;

            @include media-breakpoint-up(md) {
                font-size: 14px;
            }
        }
    }

    &__callback {
        margin-top: 12px;
        border-radius: 4px;
        background: $burger-color;
        font-size: 18px;
        font-weight: 700;
        color: $white;

        @include media-breakpoint-up(md) {
            margin-top: 0;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 400;
        }
    }
}

@import './upward/styles';
