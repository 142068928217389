body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-size: 12px;

    @include media-breakpoint-up(lg) {
        font-size: 14px;
    }
}

@import 'header/styles';
@import 'menu-block/styles';
@import 'topmenu/styles';
@import 'side-menu/styles';
@import 'typografic/styles';
@import 'footer/styles';
@import 'callback-form/styles';
@import 'breadcrumbs/styles';
@import 'content-box/styles';
@import 'pagination/styles';
@import 'side-order/styles';
