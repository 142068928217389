.main-content {
    padding: 26px 28px 36px;

    &__container {

        @include media-breakpoint-up(lg) {
            @include wrapper;
        }
    }
}
