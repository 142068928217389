.header-logo {

    &__slogan {
        display: none;
        color: $note-color;

        @include media-breakpoint-up(lg) {
            display: block;
            font-size: 12px;
            text-transform: uppercase;
        }
    }
}
