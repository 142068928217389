.header {

    position: relative;
    width: 100%;
    background: $header-bg url($img-path + 'header-bg.png') repeat left top;

    @include media-breakpoint-up(lg) {
        order: 0;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 15px;

        @include media-breakpoint-up(lg) {
            @include wrapper;

            align-items: flex-start;
            padding: 15px;
        }
    }

    &__logo {
        width: 28%;

        @include media-breakpoint-up(md) {
            width: 13%;
            margin-right: 7%;
        }

        @include media-breakpoint-up(lg) {
            width: 215px;
            margin-right: 2.5%;
        }

        img {
            width: 100%;
        }
    }

    &__right {

        @include media-breakpoint-up(md) {
            flex-grow: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 3%;
        }

        @include media-breakpoint-up(lg) {
            flex-wrap: wrap;
            width: 75%;
            margin-right: 0;
        }
    }

    &__menu {
        position: absolute;
        left: -100%;
        top: 100%;
        width: 100%;
        padding: 7px 15px;
        border-top: 1px solid $link-color;
        border-bottom: 1px solid $link-color;
        background: $white;
        transition: left .8s ease-out;
        z-index: 110;

        @include media-breakpoint-up(lg) {
            position: static;
            padding: 0 0 7px;
            border: 0;
            background: none;
        }

        &.active {
            left: 0;
        }

        ul {
            list-style: none;
            padding-left: 0;

            @include media-breakpoint-up(lg) {
                display: flex;
                justify-content: flex-end;
                width: 100%;
                margin-bottom: 0;
            }
        }

        li {
            margin-bottom: 3px;

            &:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(lg) {
                margin: 0 1%;

                &:last-child {
                    margin: 0 0 0 2%;
                }
            }

            &.active {

                a {
                    font-weight: 700;
                    color: $link-color;
                    text-decoration: none;
                }
            }
        }

        a {
            font-size: 14px;
            color: $link-color;

            @include media-breakpoint-up(lg) {
                font-size: 14px;
                color: $black;
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    &__contacts {

        @include media-breakpoint-up(md) {
            height: 60px;
            padding-left: 55px;
            background: url($img-path + 'header-contacts.png') no-repeat left 6px;
        }
    }

    &__phone {
        display: block;
        font-size: 20px;
        font-weight: 700;
        color: $black;

        span {
            font-size: 12px;
            font-weight: 400;
            color: $note-color;
        }
    }

    &__address {
        display: none;

        @include media-breakpoint-up(md) {
            display: block;
        }
    }

    &__email {
        display: block;
        padding-left: 21px;
        background: url($img-path + 'mail-icon.png') no-repeat left center;
        font-weight: 700;
        font-style: italic;
        color: $link-color;

        @include media-breakpoint-up(md) {
            display: none;
        }
    }

    &__schedule {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
            height: 50px;
            padding-left: 55px;
            background: url($img-path + 'header-schedule.png') no-repeat left center;
            font-size: 12px;
        }
    }

    &__feedback {
        display: none;

        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
        }

        @include media-breakpoint-up(lg) {
            width: 200px;
        }
    }

    &__burger {
        position: relative;
        width: 37px;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        span {
            position: absolute;
            right: 0;
            top: 50%;
            width: 100%;
            height: 4px;
            margin-top: -2px;
            border-radius: 4px;
            background: $burger-color;
            transition: width .8s ease-out;

            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                right: 0;
                top: -9px;
                width: 100%;
                height: 4px;
                border-radius: 4px;
                background: $burger-color;
                transition: width .8s ease-out;
            }

            &::after {
                top: auto;
                bottom: -9px;
            }
        }

        &.active {
            display: flex;

            span {
                left: auto;
                right: 0;
                width: 14px;

                &::before,
                &::after {
                    left: auto;
                    right: 0;
                }

                &::before {
                    width: 23px;
                }

                &::after {
                    width: 5.32px;
                }
            }
        }
    }
}

@import 'logo/styles';
@import 'schedule/styles';
@import 'feedback/styles';
