aside {

    @include media-breakpoint-up(lg) {
        order: 2;
    }
}
.side-menu {
    @include make-container();

    position: relative;
    width: 100%;
    list-style: none;

    @include media-breakpoint-up(lg) {
        position: absolute;
        left: 50%;
        top: -10px;
        width: 234px;
        margin-left: -497px;
        padding: 0 11px 0 0;
        z-index: 60;

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 234px;
            height: 30px;
            background: url($img-path + 'side-menu-top.png') no-repeat left top;
            opacity: .65;
        }
    }

    &__first {
        position: relative;
        margin: 16px auto 20px;
        padding: 21px 6px 23px;
        border-radius: 4px;
        background: $burger-color;
        font-weight: 700;
        font-size: 16px;
        color: $white;
        text-align: center;
        cursor: pointer;

        @include media-breakpoint-up(lg) {
            display: none;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 35%;
            margin-top: -9px;
            width: 13px;
            height: 13px;
            border-top: 2px solid $white;
            border-left: 2px solid $white;
            transform: rotate(-45deg);
            transition: all 0.8s ease-out;
            opacity: 0;

            @include media-breakpoint-up(md) {
                left: -100%;
            }
        }

        &.active {

            &::after {
                left: 33%;
                opacity: 1;

                @include media-breakpoint-up(md) {
                    left: 43%;
                }
            }
        }
    }

    ul {
        position: absolute;
        top: 100%;
        left: -100%;
        width: 95%;
        list-style: none;
        padding: 7px 15px;
        background: $white;
        transition: left 1s ease-out;
        z-index: 100;

        @include media-breakpoint-up(md) {
            width: 50%;
        }

        @include media-breakpoint-up(lg) {
            position: static;
            width: 100%;
            background: $sidebar-bg;
        }

        &.active {
            left: 0;
            box-shadow: 3px 1px 3px 1px rgba(0, 0, 0, .2);
        }

        li {
            position: relative;
            border-bottom: 1px solid $sub-note-color;

            @include media-breakpoint-up(lg) {
                margin-right: -17px;
                border-bottom: 0;

                &:first-child {
                    margin-top: 15px;
                }
            }

            &:hover,
            &.active {
                width: calc(100% + 34px);
                position: relative;
                margin: 0 -17px 0;
                border: 2px solid $link-color;
                border-radius: 2px;
                background: $menu-active;

                a {
                    display: block;
                    width: 100%;
                    padding-left: 15px;
                    color: $black;
                }
            }
        }

        a {
            display: block;
            padding: 3px 6px;
            font-size: 13px;
            color: $link-color;
            text-transform: uppercase;

            @include media-breakpoint-up(lg) {
                padding: 10px 0 10px;
                font-size: 18px;
                color: $white;
                text-transform: none;
            }
        }
    }
}
