.main-reviews {
    padding: 34px 15px 30px;
    background: $link-color;
    box-shadow: 440px 0 0 0 $link-color;
    color: $white;

    @include media-breakpoint-up(md) {
        width: 45%;
    }

    &__title {
        font-weight: 700;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
    }

    &__item {
        position: relative;
        margin-bottom: 43px;
    }

    &__photo {
        position: absolute;
        top: -25px;
        right: -5px;
        width: 62px;
        height: 62px;
        border-radius: 50%;

        &_even {
            right: auto;
            left: -5px;
        }
    }

    &__text {
        margin-bottom: 0;
        padding: 15px 63px 18px 13px;
        border-radius: 4px;
        background: $review-bg;

        &_even {
            padding: 15px 13px 1px 63px;
        }
    }

    &__bottom {
        display: block;
        margin-top: 5px;
        text-align: right;

        &_even {
            text-align: left;
        }
    }

    &__all {
        display: block;
        padding: 18px 0 16px;
        border: 1px solid $white;
        font-size: 16px;
        color: $white;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            background: lighten($link-color, 2);
            color: $white;
            text-decoration: none;
        }

        @include media-breakpoint-up(md) {
            width: 150px;
            margin: 0 auto;
        }
    }
}
