.main-offer {
    @include make-container;

    @include media-breakpoint-up(lg) {
        @include wrapper;

        padding-left: 245px;
    }

    h1,
    h2,
    h3,
    h4 {
        color: $link-color;
    }
}
