.delivery {

    &-title {
        margin: 45px 0;
        font-weight: 700;
        font-size: 16px;
    }
}

@import 'top/styles';
@import 'advantages/styles';
