.color-purple {
    padding: 28px 0 45px;
    background: $color-purple;

    @include media-breakpoint-up(lg) {
        padding: 80px 0 30px;
    }

    &__title {
        display: block;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;

        &::after {
            display: none;
        }

        @include media-breakpoint-up(lg) {
            @include wrapper;
        }
    }

    &__container {
        @include make-container;

        margin: 20px 0 40px;
        list-style: none;

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            margin: 30px 0 60px;
            flex-wrap: wrap;
        }

        @include media-breakpoint-up(lg) {
            @include wrapper($margin: 40px auto 80px);
        }
    }

    &__item {
        position: relative;
        width: 100%;
        margin-bottom: 32px;
        padding: 7px;
        border: 1px solid $white;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            width: 32%;
        }

        &:hover {

            .color-purple__popup {
                top: 0;
            }
        }
    }

    &__image {
        position: relative;
        width: 100%;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;
    }

    &__name {
        font-weight: 700;
        font-size: 16px;
    }

    &__more {
        font-size: 12px;
        color: $more-color;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &__popup {
        display: block;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        height: 100%;
        padding: 25px 40px 35px;
        background: $category-popup;
        color: $white;
        overflow: hidden;
        transition: top .8s linear;

        &:hover {
            color: $white;
            text-decoration: none;
        }
    }

    &__caption {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &__text {
        line-height: 1.2em;
    }
}
