$img-path: '/assets/tpl/img/';

$white: #fff;
$black: #000;
$link-color: #005b01;
$note-color: #666;
$sub-note-color: #999;
$burger-color: #090;
$header-bg: #eee;
$year-color: #333;
$base-yellow: #cc0;
$base-orange: #ffb614;
$sidebar-from: #007b02;
$sidebar-to: #009502;
$sidebar-bg: linear-gradient(to right, $sidebar-from 0%, $sidebar-to 100%);
$header-after: #008e02;
$color-purple: #fff1da;
$more-color: #5b5b5b;
$category-popup: rgba(0, 91, 1, .8);
$important-bg: #090;
$advantages-title: #d8d115;
$review-from: #00d203;
$review-to: #007a0e;
$review-bg: linear-gradient(-37deg, $review-from 0%, $review-to 100%);
$gallery-bg: #fff8ec;
$menu-from: #bcd303;
$menu-to: #bce201;
$menu-active: linear-gradient(to right, $menu-from 0%, $menu-to 100%);
$action-bg: #ffb000;
$action-color: #f00;
$product-more: #292929;
$mod-border: #c9c9c9;
$reviews-border: #a2d5a2;
$reviews-title: #ddd;
$more-color: #6f6f6f;
$table-bg: #e4e4e4;
$success-table: #dff0d8;

$advantages-bg: rgba(216, 209, 21, .5);
$advantages-item: rgba(255, 255, 255, .6);
