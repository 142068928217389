.services-top {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        margin-top: 37px;
    }

    &__text {
        line-height: 1.4em;
        font-size: 19px;
    }
}
