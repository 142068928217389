.main-about {
    padding: 26px 23px 23px;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        padding: 60px 0 15px;
    }

    @include media-breakpoint-up(lg) {
        @include wrapper();

        padding: 80px 0 15px;
    }

    &__year {
        float: left;
        margin: 0 10px 17px 0;
        line-height: .9em;
        font-size: 30px;
        font-weight: 700;
        color: $year-color;

        @include media-breakpoint-up(md) {
            font-size: 40px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 60px;
        }

        span {
            display: block;
            font-size: 18px;
            text-align: right;
        }
    }

    &__name {
        margin-bottom: 15px;
        font-weight: 700;
        color: $year-color;
    }

    &__text {

        @include media-breakpoint-up(md) {
            width: 75%;
        }

        &::before {
            content: '';
            display: block;
            clear: both;
        }
    }
}
