.header-schedule {

    @include media-breakpoint-up(md) {
        display: flex;
        flex-direction: column;
        justify-content: space-evently;
    }

    &__item {
        @include media-breakpoint-up(md) {
            font-size: 14px;
        }
    }
}
