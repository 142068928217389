.breadcrumbs {

    @include make-container;

    display: flex;
    justify-content: flex-start;
    list-style: none;

    @include media-breakpoint-up(lg) {
        //padding-left: 249px;
        margin: 13px 0 0;
        padding-left: 0;
    }

    a {
        font-size: 12px;
        color: $black;
        text-decoration: underline;

        &:hover {
            color: $black;
            text-decoration: none;
        }
    }
}
