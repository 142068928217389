.delivery-top {
    margin-bottom: 26px;

    &__item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 16px;
    }

    &__text {

        &::before {
            content: '-';
            margin-right: 5px;
        }
    }

    &__line {
        width: 100%;
        height: 3px;
        margin: 13px 0 26px;
        background: $header-after;

        @include media-breakpoint-up(md) {
            width: 172px;
        }

        @include media-breakpoint-up(xl) {
            margin: 10px 0 -15px 153px;
        }
    }
}
