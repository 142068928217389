.callback-form {
    top: 20px;
    left: 50%;
    margin-left: 0;
    transform: translateX(-50%);
    width: 95%;

    @include media-breakpoint-up(md) {
        top: 50px;
        width: 400px;
    }

    .jxo-overlay-form-inner {
        padding: 10px;

        @include media-breakpoint-up(md) {
            padding: 20px;
        }

        .title {
            margin: -10px -10px 15px;
            padding: 10px;
            font-size: 18px;

            @include media-breakpoint-up(md) {
                margin: -20px -20px 15px;
                padding: 20px;
                font-size: 20px;
            }
        }
    }

    .form-group {
        margin-bottom: 8px;

        @include media-breakpoint-up(md) {
            margin-bottom: 16px;
        }
    }

    input {
        height: 1.9em;
        padding: 3px 6px;
        line-height: 1em;

        @include media-breakpoint-up(md) {
            height: calc(1.5em + .75rem + 2px);
            padding: 6px 12px;
            line-height: 1.5em;
        }

        &[type='checkbox'] {
            position: relative;
            bottom: -7px;
        }

    }
}

.fixed-callback {
    display: block;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 61px;
    height: 61px;
    border-radius: 4px;
    background: url($img-path + 'fixed-callback.png') no-repeat center;
    font-size: 0;
    z-index: 110;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.jxo-step-form {
    margin-bottom: 2rem;
}

.jxo-step-form,
.callback-form {

    input {
        &[type='submit'] {
            background: $burger-color;
            border-radius: 4px;
            color: $white;
            text-align: center;
            cursor: pointer;

            &:hover {
                background: darken($burger-color, 2);
                color: $white;
            }
        }
    }

}

.pre-order {

    &__images {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;

        img {
            width: 100%;
            margin-bottom: 1em;

            @include-media-breakpoint(md) {
                width: 48%;
            }
        }
    }
}
