.reviews-form {

    &__container {

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
        }
    }

    &__group {

        @include media-breakpoint-up(md) {
            width: 48%;

            &:nth-child(5) {
                width: 100%;
            }
        }
    }

    &__control {
        @include media-breakpoint-up(md) {
            width: 100%;
        }
    }

    &__button {
        @include button;
    }
}
