.services-advantage {

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    &__item {
        margin-bottom: 30px;

        @include media-breakpoint-up(md) {
            width: 48%;

            &:nth-child(2) {
                order: 2;
                width: 100%;
            }

            &:nth-child(3) {
                order: 1;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 100%;

            &:nth-child(2) {
                order: 1;
            }

            &:nth-child(3) {
                order: 2;
            }
        }
    }

    &__title {
        display: flex;
        align-items: center;
    }

    &__icon {

        @include media-breakpoint-up(md) {
            margin-right: 33px;
        }
    }

    &__name {
        position: relative;
        font-weight: 700;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -15px;
            left: 0;
            width: 100%;
            height: 3px;
            background: $header-after;

            @include media-breakpoint-up(lg) {
                width: 172px;
            }
        }
    }

    &__text {
        line-height: 1.4em;
        font-size: 16px;
    }

}
