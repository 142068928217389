@import 'variables';

@mixin wrapper($width: 992px, $margin: 0 auto) {
    width: $width;
    margin: $margin;
}

@mixin content-wrapper($width: 992px, $margin: 0 auto) {
    width: $width;
    margin: $margin;
    padding-left: 249px;
}

@mixin button($display: inline-block, $width: auto) {
    display: $display;
    width: $width;
    padding: 6px 12px;
    border-radius: 4px;
    background: $burger-color;
    line-height: 14px;
    vertical-align: middle;
    font-size: 14px;
    color: $white;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;

    @include media-breakpoint-up(md) {
        border-radius: 10px;
    }

    &:hover {
        background: darken($burger-color, 2);
        color: $white;
        text-decoration: none;
    }
}
