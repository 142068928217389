.main-important {
    padding: 0 28px;

    @include media-breakpoint-up(lg) {
        @include wrapper;
    }

    &__title {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 700;
    }

    &__green {
        margin: 0 -28px 25px;
        padding: 20px 28px;
        background: $important-bg;
        color: $white;
    }

    ul {
        margin-bottom: 0;
        padding-left: 0;
        list-style: inside;
    }

    .h2 {
        font-weight: 700;
        font-size: 16px;
    }
}
