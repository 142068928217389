.main-slider {
    background: url($img-path + 'main-slider-adaptive.jpg') repeat-x top center;

    @include media-breakpoint-up(lg) {
        order: 3;
        margin-top: -78px;
        background: url('/uploads/slider/background.jpg') no-repeat center 6px;
        background-size: 100%;
    }

    &__banner {
        position: relative;
    }

    &__container {
        position: relative;
        padding-left: 0;
    }

    &__item {
        position: absolute;
        padding: 15px;
        opacity: 0;
    }

    &__image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        img {
            width: 100%;
        }
    }

    &__caption {
        position: absolute;
        top: 15px;
        right: 15px;
        bottom: 15px;
        left: 15px;
        width: calc(100% - 30px);
        z-index: 20;

        @include media-breakpoint-up(lg) {
            top: 30%;
            right: 10%;
            left: auto;
            width: 728px;
            margin-top: -50px;
        }

        img {
            width: 100%;
        }
    }

    &__text {
        position: absolute;
        top: 35%;
        left: 50%;
        color: $white;
        text-align: center;
        transform: translateX(-50%);
        z-index: 30;

        @include media-breakpoint-up(md) {
            top: 40%;
            font-size: 14px;
            text-transform: uppercase;
        }

        @include media-breakpoint-up(lg) {
            top: 45%;
            right: 10%;
            left: auto;
            width: 728px;
            transform: translateX(0);
        }
    }

    &__note {
        position: absolute;
        top: 45%;
        left: 50%;
        font-size: 14px;
        color: $white;
        text-transform: uppercase;
        text-align: center;
        transform: translateX(-50%);
        z-index: 40;

        @include media-breakpoint-up(md) {
            top: 50%;
            font-size: 24px;
        }

        @include media-breakpoint-up(lg) {
            top: 50%;
            right: 10%;
            left: auto;
            width: 728px;
            transform: translateX(0);
        }
    }

    &__button {
        position: absolute;
        top: 57%;
        left: 50%;
        padding: 1px 3px;
        border: 1px solid $white;
        color: $white;
        text-transform: uppercase;
        text-align: center;
        transform: translateX(-50%);
        z-index: 50;

        @include media-breakpoint-up(md) {
            top: 62%;
            padding: 7px 15px;
            font-size: 15px;
        }

        @include media-breakpoint-up(lg) {
            top: 58%;
            right: 22.8%;
            left: auto;
            width: 190px;
            padding: 7px 0;
        }

        &:hover {
            background: rgba(255, 255, 255, .1);
            color: $white;
            text-decoration: none;
        }
    }
}
