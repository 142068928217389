.text-contacts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px 0;

    &__left {
        margin-bottom: 13px;

        @include media-breakpoint-up(md) {
            width: 35%;
        }

        p {
            margin: 0;
            line-height: 2em;
        }
    }

    &__right {

        @include media-breakpoint-up(md) {
            width: 65%;
        }

        p {
            margin: 0;
            padding-left: 40px;
            line-height: 2em;
            background: url('/assets/tpl/img/text-contacts-icons.png') no-repeat left 5px;

            &:nth-child(2) {
                background-position: left -25px;
            }

            &:last-child {
                background-position: left -53px;
            }

            a {
                color: $black;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
