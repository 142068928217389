.topmenu {
    @include make-container;

    margin-top: 7px;
    list-style: none;

    @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @include media-breakpoint-up(lg) {
        @include wrapper;

        align-items: center;
        order: 1;
        position: relative;
        padding: 0 0 0 230px;
        background: $link-color;
        z-index: 10;
    }

    li {
        margin-bottom: 10px;
        padding: 14px 6px 12px;
        border-radius: 4px;
        background: $burger-color;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: 48%;
            align-self: flex-start;
        }

        @include media-breakpoint-up(lg) {
            width: 24%;
            padding: 29px 6px 35px;
            background: none;
        }

        &.clicked,
        &:hover {

            margin: 21px 0;

            @include media-breakpoint-up(lg) {
                margin: -15px 0 -23px;
            }

            a {
                font-weight: 700;
                text-decoration: none;
            }

            .note {
                display: block;
                margin-top: 7px;
                font-size: 11px;
                color: $white;
            }
        }
    }

    a {
        line-height: 1.2em;
        font-size: 14px;
        font-weight: 700;
        color: $base-yellow;

        @include media-breakpoint-up(lg) {
            font-size: 13px;
            font-weight: 400;
        }

        .note {
            display: none;
        }
    }
}
