.content-box {
    @include make-container

    @include media-breakpoint-up(lg) {
        @include content-wrapper

        margin-bottom: 52px;
    }

    h1 {
        display: block;
        color: $link-color;
    }

    h2 {
        display: block;
        margin: 16px 0 8px;
        font-size: 21px;
        color: $link-color;
    }

    h3,
    h4,
    h5 {
        color: $link-color;
    }

    table {
        tr {
            &.success {
                td {
                    background: $success-table;
                }
            }
        }
    }

    .action_kitchen { // sass-lint:disable-line class-name-format
        padding: 7px;
        background: $action-bg;
        color: $action-color;
        font-size: 14px;
        text-align: center;

        &::after {
            display: none;
        }
    }

    .products-gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        list-style: none;
        padding-left: 0;

        li {
            @include media-breakpoint-up(md) {
                width: 32%;
            }

            margin-bottom: 1rem;
            width: 100%;
        }

        a {
            display: block;
            width: 100%;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .important {
        background: $burger-color;
        color: $white;
        padding: 15px;

        &__container {
            @include media-breakpoint-up(md) {
                width: 75%;
                margin-right: 1%;
            }
        }

        &__button {
            @include media-breakpoint-up(md) {
                border-radius: 10px;
            }

            background: $action-bg;
            border-radius: 4px;
            color: $white;
            display: block;
            margin: 0 auto;
            padding: 7px 0;
            text-align: center;
            width: 150px;
        }
    }

    .products {
        @include media-breakpoint-up(lg) {
            padding: 0;
        }

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }

        list-style: none;
        padding: 0 14px;

        li {
            margin-bottom: 23px;

            &:last-child {
                margin-bottom: 0;
            }

            @include media-breakpoint-up(md) {
                width: 32%;
            }
        }

        &__title {
            color: $link-color;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 16px;
        }

        &__photo {
            display: block;
            width: 100%;

            img {
                display: block;
                width: 100%;
            }
        }

        &__price {
            margin-top: 14px;
            font-size: 12px;
            text-align: right;

            &-value {
                color: $link-color;
                font-size: 20px;
                font-weight: 700;

                @include media-breakpoint-up(lg) {
                    font-size: 18px;
                }
            }
        }

        &__more {
            display: block;
            width: 100%;
            margin-top: 13px;
            padding: 11px 0 7px;
            border-radius: 4px;
            background: $action-bg;
            font-size: 18px;
            font-weight: 700;
            color: $product-more;
            text-align: center;
        }
    }

    .pv-image {
        display: block;
        width: 100%;
        margin-bottom: 14px;

        img {
            width: 100%;
        }
    }

    .pv-price {
        font-size: 12px;
        text-align: right;

        span {
            font-size: 24px;
            font-weight: 700;
            color: $link-color;
        }
    }

    .pv__button {
        display: block;
        width: 250px;
        margin: 0 auto;
        padding: 11px 0 7px;
        border-radius: 4px;
        background: $action-bg;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
        color: $product-more;

        &:hover {
            background: darken($action-bg, 2);
            color: $product-more;
        }
    }

    .btn-frez {
        display: block;
        width: 200px;
        margin: 21px auto 0;
        padding: 7px 0;
        border: 2px solid $burger-color;
        border-radius: 4px;
        color: $burger-color;
        text-align: center;
    }

    .tabs {
        width: calc(100% + 60px);
        margin-left: -30px;
        margin-right: -30px;

        @include media-breakpoint-up(lg) {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        &-nav {
            @include make-row;

            width: 100%;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            list-style: none;
            margin: 0 auto;
            padding: 16px 15px 14px;
            background: $burger-color;

            li {
                margin: 0 .5% 12px;

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                }

                &.active {

                    a {
                        border-bottom: 0;
                        color: $white;
                    }
                }
            }

            a {
                font-size: 11.5px;
                font-weight: 400;
                border-bottom: 1px dotted $black;
                color: $black;

                &:hover {
                    text-decoration: none;
                    border-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    font-size: 14px;
                }
            }
        }

        &-container {
            margin-top: 21px;
        }

        &-tab {
            padding: 15px 15px 15px 30px;

            ul {
                list-style: none;
                padding-left: 0;
                display: flex;
                justify-content: space-evenly;
                flex-wrap: wrap;
            }

            li {
                @include media-breakpoint-up(md) {
                    width: 24%;
                    margin-bottom: 14px;
                }

                @include media-breakpoint-up(lg) {
                    width: 18%;
                }

                width: 32%;
                margin-bottom: 7px;
                text-align: center;

                a {
                    display: block;
                    width: 100%;

                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }

            .mod-block {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                position: relative;
                padding: 15px 4px 12px;
                border-bottom: 2px solid $mod-border;

                @include media-breakpoint-up(md) {
                    border-bottom: 0;
                }


                .mod-image {

                    @include media-breakpoint-up(md) {
                        width: 15%;
                    }

                    img {
                        width: 100%;
                    }
                }

                .mod-info {
                    flex-grow: 1;
                }

                .mod-header {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    font-size: 16px;

                    @include media-breakpoint-up(md) {
                        flex-direction: row;
                        padding: 15px;
                        background: url($img-path + 'mod-header.png') repeat left top;
                    }

                    &::after {
                        display: none;
                    }

                    b {
                        display: block;

                        @include media-breakpoint-up(md) {
                            margin-right: 3px;
                        }
                    }
                }

                .mod-more {
                    padding-top: 30px;
                    font-size: 12px;
                    color: $more-color;
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }

                    @include media-breakpoint-up(md) {
                        display: none;
                    }
                }

                .mod-chars {
                    display: none;
                    margin-top: 39px;

                    @include media-breakpoint-up(md) {
                        display: table;
                        width: 100%;
                        margin-top: 13px;
                    }

                    tr {

                        &:nth-child(even) {
                            background: $table-bg;
                        }
                    }

                    th {
                        padding: 25px 7px 23px;
                        background: $burger-color;
                        vertical-align: middle;
                        color: $white;
                        text-align: center;

                        @include media-breakpoint-up(md) {
                            padding: 10px;
                            border: 1px solid $black;
                            background: none;
                            color: $black;
                        }
                    }

                    td {
                        padding: 20px 3px;
                        text-align: center;

                        @include media-breakpoint-up(md) {
                            padding: 4px 10px;
                            border: 1px solid $black;
                        }
                    }
                }
            }
        }
    }
}
