.main-stages {
    padding: 34px 28px 30px;
    background: $color-purple;
    box-shadow: -500px 0 0 0 $color-purple;

    @include media-breakpoint-up(md) {
        width: 55%;
    }

    &__top {

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 700;
        color: $year-color;
        text-transform: uppercase;
        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: right;
            margin-right: 15px;
            padding-right: 15px;
            border-right: 1px solid $black;
        }

        &::after {
            display: none;
        }
    }

    &__list {
        margin-top: 24px;
        background: url($img-path + 'stages-list.png') repeat-y 42px top;

        @include media-breakpoint-up(md) {
            background: none;
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 94px;
        margin-bottom: 14px;
        padding-left: 112px;
        border-radius: 94px 0 0 94px;
        line-height: 1.2em;
        font-size: 16px;
        color: $link-color;

        &:first-child {
            background: $white url($img-path + 'stages-project.png') no-repeat center left;
        }

        &:nth-child(2) {
            background: $white url($img-path + 'stages-money.png') no-repeat center left;
        }

        &:nth-child(3) {
            background: $white url($img-path + 'stages-scarf.png') no-repeat center left;
        }

        &:nth-child(4) {
            background: $white url($img-path + 'stages-hands.png') no-repeat center left;
        }

        &:last-child {
            background: $white url($img-path + 'stages-guarantie.png') no-repeat center left;
        }

        @include media-breakpoint-up(md) {
            position: relative;
            margin-right: -28px;
            padding-right: 15px;

            &:nth-child(odd) {

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 65px;
                    left: 105px;
                    width: 10px;
                    height: 80px;
                    background: url($img-path + 'stages-list.png') no-repeat center top;
                    transform: rotate(-45deg);
                }

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: -45px;
                    left: 105px;
                    width: 10px;
                    height: 75px;
                    background: url($img-path + 'stages-list.png') no-repeat center top;
                    transform: rotate(45deg);
                    z-index: 10;
                }
            }

            &:nth-child(even) {
                margin-left: 104px;
            }

            &:first-child {
                background: $white url($img-path + 'stages-project.png') no-repeat center left;

                &::before {
                    display: none;
                }
            }

            &:last-child {
                margin-bottom: 0;
                background: $white url($img-path + 'stages-guarantie.png') no-repeat center left;

                &::after {
                    display: none;
                }
            }
        }
    }
}
