.product-view {

    &__top {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__image {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 43%;
        }

        img {
            width: 100%;
        }
    }

    &__right {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 53%;
        }

        .pv-short {
            font-size: 16px;

            &::first-letter {
                text-transform: uppercase;
            }
        }
    }

    &__order {
        @include button($display: block, $width: 250px);

        margin: 0 auto;
        padding: 10px 0 13px;
        background: $base-orange;
    }
}
