.main-advantages {
    @include make-container;

    padding-bottom: 26px;
    background: $advantages-bg;

    @include media-breakpoint-up(md) {
        position: relative;
        padding-top: 15px;
        background: $advantages-bg url($img-path + 'advantages-s.jpg') no-repeat 150% top;
        background-size: contain;
    }

    @include media-breakpoint-up(lg) {
        background: url($img-path + 'advantages-s.jpg') no-repeat 150% top;
        background-size: 100%;
    }

    &__title {
        margin: 0 -15px;
        padding: 23px 12px 20px;
        background: $advantages-bg;
        font-size: 19px;
        font-weight: 700;
        color: $white;
        text-transform: uppercase;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: 60%;
            margin: 15px 15px 0 0;
            background: $advantages-title;
            font-size: 20px;
        }

        &::after {
            display: none;
        }
    }

    &__container {
        @include media-breakpoint-up(lg) {
            @include wrapper;
        }
    }

    &__content {
        counter-reset: item;

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            width: 60%;
            background: $advantages-bg;
        }
    }

    &__item {
        position: relative;
        width: 75%;
        margin: 11px auto;
        padding-top: 43px;
        font-size: 16px;
        text-align: center;
        z-index: 10;

        &::after {
            counter-increment: item;
            content: counter(item);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            line-height: 1em;
            font-size: 100px;
            font-weight: 700;
            color: $advantages-item;
            text-align: center;
            z-index: 10;

            @include media-breakpoint-up(lg) {
                font-size: 150px;
            }
        }

        @include media-breakpoint-up(md) {
            width: 48%;
        }

        @include media-breakpoint-up(lg) {
            font-size: 18px;
        }
    }

    &__text {
        position: relative;
        z-index: 20;
    }

    &__button {
        display: block;
        padding: 16px 23px 14px;
        border-radius: 4px;
        background: $burger-color;
        font-size: 17px;
        font-weight: 700;
        color: $white;
        text-align: center;

        &:hover {
            background: darken($burger-color, 2);
            color: $white;
            text-decoration: none;
        }

        @include media-breakpoint-up(md) {
            position: absolute;
            bottom: 10%;
            right: 10%;
            font-size: 24px;
            border-radius: 10px;
        }
    }
}
