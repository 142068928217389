.text-content {

    p {
        line-height: 1.4em;
        font-size: 16px;

        &.bold {
            font-weight: 700;
        }
    }
}
