.menu-block {

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-direction: column;
        position: relative;
        background: $link-color;
    }

    &__inner {
        padding: 12px 0 19px;
        background: $color-purple;

        @include media-breakpoint-up(lg) {
            padding: 0;
            background: none;
        }
    }

    .side-order {
        display: block;
        width: 60px;
        height: 60px;
        position: fixed;
        right: 1%;
        bottom: 0;
        background: url($img-path + 'side_order.png') no-repeat center;

        @include media-breakpoint-up(lg) {
            display: block;
            left: 50%;
            top: 50vh;
            width: 234px;
            margin: 50px 0 50px -497px;
            padding: 100px 0 0;
            background: url($img-path + 'girl-mini.png') 120px 0 no-repeat;
            transition: top .3s linear;
            z-index: 60;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            color: $white;
            border-radius: 10px 0 21px 10px;
            margin-bottom: 0;
            font-weight: normal;
            text-align: center;
            vertical-align: middle;
            touch-action: manipulation;
            cursor: pointer;
            background-image: none;
            border: 1px solid transparent;
            white-space: nowrap;
            padding: 6px 12px;
            font-size: 0;
            line-height: 1.42857143;

            &:hover {
                background: $link-color;
            }

            @include media-breakpoint-up(md) {
                right: 16px;
                top: 100%;
                width: auto;
                height: auto;
                background: $burger-color;
                font-size: 14px;
            }
        }

        &.to-right {

            @include media-breakpoint-up(lg) {
                left: auto;
                top: 70% !important; //sass-lint:disable-line no-important
                right: 0;
            }
        }
    }
}
