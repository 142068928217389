.reviews {

    &__button {
        @include button;
    }

    &__item {
        margin: 10px 0;
        border: 1px solid $reviews-border;
        border-radius: 12px;
        background-color: $white;
    }

    &__title {
        font-size: 14px;
        font-weight: 400;
        color: $black;
        padding: 15px 15px 10px;
        border-radius: 12px 12px 0 0;
        background-color: $reviews-title;
    }

    &__name {
        color: $link-color;
    }

    &__date {
        font-size: 12px;
        color: $note-color;
    }

    &__text {
        margin: 10px 0;
        line-height: 1.2em;
        font-size: 12px;
    }

    &__answer {
        padding: 15px 15px 10px;

        p {
            margin: 10px 0;
            line-height: 1.2em;
            font-size: 12px;
        }
    }
}

@import 'form/styles';
