.header-feedback {

    @include media-breakpoint-up(lg) {
        padding-top: 20px;
        background: url($img-path + 'h-online.png') no-repeat right top;
    }

    &__title {
        margin-bottom: 1px;
        font-style: italic;
        color: $sub-note-color;

        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }
    }

    &__callback {
        padding: 6px 0;
        background: $burger-color;
        border-radius: 10px;
        font-size: 14px;
        color: $white;
        text-align: center;

        &:hover {
            color: $white;
            background: darken($burger-color, 1.5);
            text-decoration: none;
        }

        @include media-breakpoint-up(lg) {
            border-radius: 10px 0 10px 10px;
        }
    }

    &__email {
        margin-top: 2px;
        padding-left: 30px;
        background: url(/assets/tpl/img/h-email.png) 0 50% no-repeat;
        font-weight: 700;
        color: $link-color;
        text-decoration: underline;

        &:hover {
            color: $link-color;
            text-decoration: none;
        }

        @include media-breakpoint-up(lg) {
            font-size: 14px;
        }
    }
}
