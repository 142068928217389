.pagination {
    margin: 26px 0;

    .pagination {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        list-style: none;
        padding-left: 0;

        li {
            margin-right: 4%;

            @include media-breakpoint-up(md) {
                margin-right: 3%;
            }
        }

        a,
        span {
            font-size: 15px;
        }
    }
}
