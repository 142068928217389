h1,
h2,
h3,
h4,
h5,
h6 {
    display: inline-block;
    position: relative;
    margin: 15px 0 14px;
    padding: 0 0 10px;
    line-height: 30px;
    font-size: 24px;
    text-align: left;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 172px;
        height: 3px;
        background: $header-after;
    }
}

h2 {

    &::after {
        width: 152px;
        height: 2px;
    }
}

h3 {

    &::after {
        width: 132px;
        height: 1px;
    }
}

h4 {

    &::after {
        width: 112px;
        height: 1px;
    }

    &.h2 {
        margin: 10px 0;
        line-height: 20px;
        font-weight: 400;
        font-size: 18px;
        color: $link-color;
    }
}
