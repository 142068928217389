.upward {
    display: block;
    width: 60px;
    height: 60px;
    position: fixed;
    right: 1%;
    bottom: 11.3%;
    background: $burger-color;
    opacity: 0;
    transition: opacity .8s linear;

    @include media-breakpoint-up(lg) {
        bottom: 1%;
    }

    @include media-breakpoint-up(xl) {
        bottom: 2%;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        width: 50%;
        height: 4.5px;
        position: absolute;
        top: 50%;
        left: 41%;
        background: $white;
        transform: rotate(45deg);
    }

    &::after {
        left: 9%;
        transform: rotate(-45deg);
    }

    &:hover {
        background: $link-color;
    }

    &.visible {
        opacity: 1;
    }
}
