.main-gallery {
    padding: 34px 29px 0;
    background: $gallery-bg;

    @include media-breakpoint-up(lg) {
        padding: 34px 0 0;
    }

    &__title {
        display: block;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;

        &::after {
            display: none;
        }
    }

    &__container {
        list-style: none;
        padding-left: 0;

        @include media-breakpoint-up(md) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        @include media-breakpoint-up(lg) {
            @include wrapper();
        }
    }

    &__item {
        width: 100%;
        margin-bottom: 28px;

        @include media-breakpoint-up(md) {
            width: 32%;
        }

        @include media-breakpoint-up(lg) {
            width: 24%;
        }
    }

    &__link {
        display: block;
        width: 100%;
    }

    &__image {
        display: block;
        width: 100%;
    }
}
