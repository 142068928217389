.services-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    &__item {
        width: 100%;
        position: relative;
        margin: 5px .5%;

        @include media-breakpoint-up(md) {
            width: 32%;
        }

        @include media-breakpoint-up(lg) {
            width: 24%;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $sidebar-from;
            opacity: .5;
        }

        &:hover {
            &::after {
                background: none;
            }

            .services-list__name {
                text-shadow: 1px 1px $black;
            }
        }
    }

    &__image {
        display: block;
        width: 100%;
    }

    &__name {
        position: absolute;
        top: 50%;
        right: 10px;
        left: 10px;
        line-height: 1.2em;
        font-size: 16px;
        color: $white;
        transform: translateY(-50%);
        z-index: 10;
    }
}
