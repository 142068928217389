.delivery-advantages {
    margin: 37px 0;

    &__title {
        position: relative;
        font-size: 16px;
        font-weight: 700;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -16px;
            width: 172px;
            height: 3px;
            background: $header-after;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 15px;
    }

    &__item {
        width: 100%;
        font-size: 16px;
        text-align: center;

        @include media-breakpoint-up(md) {
            width: 4%;
        }

        @include media-breakpoint-up(lg) {
            width: 32%;
        }
    }

    &__icon {
        position: relative;
        left: -21px;
    }

    &__text {
        margin-top: -19px;
    }
}
