.contacts {

    &__title {
        margin: 16px 0 8px;
        padding: 0;
        line-height: 20px;
        font-size: 21px;
        font-weight: normal;
        color: $black;

        &::after {
            display: none;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__item {

        @include media-breakpoint-up(md) {
            width: 48%;
        }
    }

    &__building {
        img {
            width: 100%;
        }
    }

    &__map {
        width: 100%;
    }
}
