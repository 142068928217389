.text-certificates {
    padding-left: 0;

    &__item {
        list-style: none;
    }

    &__name {
        position: relative;
        font-size: 20px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: -20px;
            width: 172px;
            height: 2px;
            background: $header-after;
        }
    }

    &__docs {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 40px;
        padding-left: 0;
    }

    &__link {
        display: block;
        width: 48%;
        margin: 10px 1%;
        list-style: none;

        @include media-breakpoint-up(md) {
            width: 18%;
        }

        &:nth-child(5n) {
            margin-right: 0;
        }

        img {
            display: block;
            width: 100%;
        }
    }
}
